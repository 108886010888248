<template>
    <div id="Home">
        <h1 class="Titulo">BIENVENIDOS</h1>
        <!--<h1 class="Titulo2">PRÓXIMAMENTE</h1>-->

        <div class="C1">
          <a href="/barragan">
            <div class="F1C1">
              <div class="F1C1_C1">
                <img src="../assets/logo1_1.png">
              </div>
              <div class="F1C1_C2">
                <p>LABORATORIO CLÍNICO BARRAGAN</p>
                

              </div>
              <div class="ver">
                <p>VER MÁS</p>
              </div>
              <p class="LastText">Sistema de Gestión de Calidad
Certificado por ISO 9001:2015</p>
            </div>
          </a>

          <a class="btncerti2" @click="showmodal2 = !showmodal2">Ver certificado</a>

                <div v-if="showmodal2 == true" class="modalss">                        
                  <BarraganModal @listenModal="closeModal" />                        
                </div>

        </div>

        <div class="C2">
          <a href="/laason">
          <div class="F1C2">
            <div class="F1C2_C1">
              <img src="../assets/logo2_1.png">
            </div>
            <div class="F1C2_C2">
              <p>LABORATORIO</p>
              <p>DE ALIMENTOS Y AGUAS</p>
              <p>DE SONORA S.A. DE C.V.</p>
              

            </div>
            <div class="ver">
              <p>VER MÁS</p>
            </div>
              <p class="LastText2">Laboratorio Acreditado
por ISO/IEC 17025:2017</p>
          </div>
              <div class="foo">
                <p>Garmendia N°80, Col.San Benito C.P. 83190</p>
                <p>Hermosillo, Sonora.</p>
              </div>
          </a>

          <a class="btncerti" @click="showmodal = !showmodal">Ver certificado</a>

              <div v-if="showmodal == true" class="modalss">                        
                <LaasonModal @listenModal="closeModal" />                        
              </div>

        </div>

        
    </div>
</template>
<script>

import LaasonModal from './modal/LaasonModal.vue';
import BarraganModal from './modal/BarraganModal.vue';


export default {
  name: 'Home',
    data(){
        return{
            showmodal:false,
            showmodal2:false
        }
    },
    components:{
        LaasonModal,
        BarraganModal
    },
    methods: {
         closeModal(value){
            this.showmodal=value;
            this.showmodal2=value;
         }
    }
}
</script>
<style scoped>

.modalss {
  position: fixed; 
  z-index: 1; 
  padding-top: 8.208333333333334VW; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.btncerti {
  white-space: nowrap;
  height: 0vw !important;
  width: 0vw !important;
  align-items: center;
  
  background: linear-gradient(85.48deg, #6FBA91 4.15%, #76DFA6 139.07%) !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 1.2vw 6.5vw !important;
  font-size: 0.75VW !important;
  text-decoration: none !important;
  position: relative !important;
  top: 2vw !important;
  padding-left: 2.5vw !important;
    margin-top: 9vw;
    margin-left: -34vw;
}

.btncerti2 {
  white-space: nowrap;
  height: 0vw !important;
  width: 0vw !important;
  align-items: center;
  
  background: linear-gradient(85.48deg, #6FBA91 4.15%, #76DFA6 139.07%) !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 1.2vw 6.5vw !important;
  font-size: 0.75VW !important;
  text-decoration: none !important;
  position: relative !important;
  top: 2vw !important;
  padding-left: 2.5vw !important;
  margin-top: 9vw;
  margin-left: -6vw;
}

#Home{
  display: flex;
}
.Titulo{
  font-size: 3.75VW;
  font-weight: 800;
  line-height: 4.500000000000001VW;
  margin-left: 40vw;
  position: absolute;
  background: linear-gradient(269.07deg, #6FBA91 -19.33%, #0A4CA1 112.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.Titulo2{
  display: none;
}
  .C1{
    width: 50%;
    height: 48vw;
    display: flex;
    justify-content: right;
    padding-right: 5vw;
    cursor: pointer;
    background-image: url('../assets/whiteblock.png');
    background-size: 50vw 48vw;
    transition: 1s;
  }
  .C1:hover{
    background-color: rgb(230, 228, 228);
    background-image: url('../assets/Rectangle_3.png') ;
    background-repeat: no-repeat;
    background-size: 50vw 48vw;
  }
  .C1 a {
    align-self: center;
    height: 100%;
    width: 100%;
    display: flex;
    padding-left: 29vw;
  }
    .F1C1 {
      display: flex;
      align-items: center;
      float: right;
    }
      .F1C1_C1 img{
        width: 8.333333333333332VW;
        height: auto;
        background: transparent;
      }
      .F1C1_C2{
        width: 11vw;
        padding-top: 3vw;
        padding-left: 2vw;
      }
        .F1C1_C2 p{
          font-size: 1.0416666666666665VW;
          text-decoration: underline;
          color: #0A4CA1;
          font-weight: 700;
          background: transparent;
        }



  .C2{
    width: 50%;
    height: 48vw;
    display: flex;
    justify-content: left;
    padding-left: 5vw;
    background-image: url('../assets/whiteblock.png');
    background-size: 50vw 48vw;
    transition: 1s;
    cursor: pointer;
  }
  .C2:hover{
    background-color: rgb(230, 228, 228);
    background-image: url('../assets/Rectangle_4.png') ;
    background-repeat: no-repeat;
    background-size: 49vw 48vw;
  }
  .C2 a {
    align-self: center;
    height: 100%;
    width: 100%;
    display: flex;
  }
    .F1C2 {
      display: flex;
      align-items: center;
      float: left;
    }
      .F1C2_C1 img{
        width: 8.333333333333332VW;
        height: auto;
        background: transparent;
      }
      .F1C2_C2{
        width: fit-content;
        padding-top: 2.5vw;
        padding-left: 2vw;

      }
        .F1C2_C2 p{
          padding-left: 1vw;
          font-size: 1.0416666666666665VW;
          text-decoration: underline;
          margin: 0;
          color: #0A4CA1;
          font-weight: 700;
          border: 0px solid #ccc;
          border-radius: 0px;
          background: transparent;
        }

.foo {
  margin-top: 42vw;
  margin-left: -12vw;
  position: absolute;
  text-align: center;
  font-size: 0.7291666666666666VW;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.ver{
  display: none;
}

p.LastText {
    width: 10vw;
    color: #565656;
    font-size: 0.7291666666666666VW;
    font-weight: 400;
    margin-top: 19.5vw;
    margin-left: -9.5vw;
}
p.LastText2 {
    width: 8vw;
    color: #565656;
    font-size: 0.7291666666666666VW;
    font-weight: 400;
    margin-top: 19.5vw;
    margin-left: -11vw;
}

@media (max-width: 768px) {

  
  .btncerti {
    display: none !important;
  }
  .btncerti2 {
    display: none !important;
  }

.ver{
  display: block;
  color: #6FBA91;
  font-size: 3.3816425120772946VW;
  text-decoration: underline;
  font-weight: 700;
    margin-top: 10vw;
    margin-left: 20vw;
}

#Home {
  display: block;
}
.Titulo {
  font-size: 7.246376811594203VW;
  font-weight: 800;
  line-height: 15.5VW;
  margin-left: 27vw;
  position: absolute;
  background: linear-gradient(269.07deg, #6FBA91 -19.33%, #0A4CA1 112.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Titulo2 {
  display: block;
  font-size: 7.246376811594203VW;
  font-weight: 800;
  line-height: 15.5VW;
  margin-left: 25vw;
  position: absolute;
  background: linear-gradient(269.07deg, #6FBA91 -19.33%, #0A4CA1 112.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.C1 {
  width: 21vw;
    height: 100vw;
    display: flex;
    justify-content: right;
    padding-right: 0vw;
    background-image: none;
}
.C1:hover{
  background-image: none;
  background-color: white;
}
  .F1C1 {
    display: block;
    align-items: center;
    float: right;
    margin-top: 18vw;
  }
    .F1C1_C1 img {
      margin-left: 14vw;
      width: 28.985507246376812VW;
      height: auto;
      background: transparent;
    }
    .F1C1_C2{
    width: 0vw;
    padding-top: 5vw;
    padding-left: 2vw;
}
    .F1C1_C2 p {
      font-size: 5.314009661835748VW;
      text-decoration: underline;
      color: #0A4CA1;
      font-weight: 700;
      background: transparent;
      width: 54vw;
      text-align: center;
    }


.C2 {
    border-top: 1px solid gray;
    padding-top: 5vw;
    width: fit-content;
    height: 90vw;
    display: flex;
    justify-content: left;
    padding-left: 0vw;
    background-image: none;
    margin-left: 20vw;
}
.C2:hover{
  background-image: none;
  background-color: white;
}

.F1C2 {
    display: block;
    align-items: center;
    float: left;
}
.F1C2_C2 {
    width: fit-content;
    padding-top: 5vw;
    padding-left: 0vw;
    text-align: center;
}
.F1C2_C1 img {
    margin-left: 14vw;
    width: 28.985507246376812VW;
    height: auto;
    background: transparent;
}
.F1C2_C2 p {
    padding-left: 1vw;
    font-size: 5.314009661835748VW;
    text-decoration: underline;
    margin: 0;
    color: #0A4CA1;
    font-weight: 700;
    border: 0px solid #ccc;
    border-radius: 0px;
    background: transparent;
}

  .foo {
    display: none;
  }


  p.LastText {
    display: none;
  }
  p.LastText2 {
    display: none;
  }


}
   
</style>
